import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
/* eslint-enable */
import { ValidatorComponent } from 'react-form-validator-core';

export default class DatePickerValidator extends ValidatorComponent {
    renderValidatorComponent() {
        /* eslint-disable no-unused-vars */
        const {
            error,
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            containerProps,
            ...rest
        } = this.props;
        const { isValid } = this.state;
        return (

            <DatePicker
                {...rest}
                slotProps={{
                    textField: {
                        helperText: (!isValid && this.getErrorMessageProxy()) || helperText,
                        error: !isValid || error
                    },
                }}
            />


        );
    }

    getErrorMessageProxy() {
        let m = this.getErrorMessage();
        return m;
    }
}