import React from 'react';
import {
    Button, TextField, Grid,
    InputAdornment, FormGroup, FormControlLabel, Typography,
    Checkbox
} from '@mui/material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Api from '../../services/Api';

export default class GroupsAutoGenerator extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }

    }

    handleSubmit = async (e) => {
        e.preventDefault();

        try {
            var sexSplit = [];
            if (e.target.male.checked) { sexSplit.push("male"); }
            if (e.target.female.checked) { sexSplit.push("female"); }


            var data = { 
                divisionNameTemplate: e.target.nameTemplate.value,
                sexSplit: sexSplit,
                beltSplit: e.target.beltSplit.checked ?? false,
                ageSplit: e.target.ageSplit.value == "" ? [] : e.target.ageSplit.value.split('\n'),
                minAge: e.target.ageFrom.value == "" ? null : parseInt(e.target.ageFrom.value),
                maxAge: e.target.ageTo.value == "" ? null : parseInt(e.target.ageTo.value),
                weightInterval: e.target.weightTo.value == "" ? null : Number(e.target.weightInterval.value),
                minWeight: e.target.weightFrom.value == "" ? null : Number(e.target.weightFrom.value),
                maxWeight: e.target.weightTo.value == "" ? null : Number(e.target.weightTo.value), 
                applicationsWithoutGroupsOnly: e.target.applicationsWithoutGroupsOnly.checked ?? false, 
            };
            var body = JSON.stringify(data);
            return Api.post('api/tournaments/' + this.props.tournamentId + '/divisions/auto',
                body, {}, true).then((response) => {
                    console.log(response);
                    //this.props.navigate("/tournaments/" + tournamentInfo.id + "/registered");
                    return response;
                });


        }
        catch (error) {

            /* Log error to console */
            console.error(error)
        }

    }

    render() {
        return (
            <ValidatorForm
                ref="form" noValidate instantValidate={false} onSubmit={(e) => {
                    e.preventDefault();
                    this.setState({ isSaving: true });
                    this.handleSubmit(e).then((data) => {
                        if (data.status != 200) {
                            alert(data.statusText);
                        } else {
                            this.props.onDivisionsGenerated();
                        }
                    })
                    // this.setState({ snackbarOpen: true, snackbarMessage: "Заявка на регистрацию отправлена" });
                }} >
                <Grid container xs={12} sm={12} spacing={3} padding={3}>
                    <Grid item xs={12}>
                        <TextValidator
                            id="nameTemplate"
                            name='nameTemplate'
                            label='шаблон названия'
                            fullWidth
                            variant='outlined'
                            required
                        //validators={['required']}
                        //errorMessages={['введите шаблон']}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel id="applicationsWithoutGroupsOnly" name="applicationsWithoutGroupsOnly" control={<Checkbox defaultChecked />} label="Учитывать только заявки не в группах" />
                    </Grid>
                    <Grid item xs={2}>
                        <FormGroup>
                            <Typography>Разбить по полу</Typography>
                            <FormControlLabel id="male" name="male" control={<Checkbox defaultChecked />} label="Мужчины" />
                            <FormControlLabel id="female" name="female" control={<Checkbox defaultChecked />} label="Женщины" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel id="beltSplit" name="beltSplit" control={<Checkbox defaultChecked />} label="Разбить по поясу" />
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container xs={12} spacing={1}>
                            <Grid item xs={12}><Typography>Разбить по возрасту</Typography></Grid>
                            <Grid item xs={6}>
                                <TextValidator
                                    id="ageFrom"
                                    name='ageFrom'
                                    label='от'
                                    fullWidth
                                    variant='outlined'

                                    validators={['matchRegexp:^[1-9]([0-9]{1,2})?([,.][0-9]{0,2})?$']}
                                    errorMessages={['введите положительное число']}
                                />
                            </Grid><Grid item xs={6}>
                                <TextValidator
                                    id="ageTo"
                                    name='ageTo'
                                    label='до'
                                    variant='outlined'
                                    fullWidth
                                    validators={['matchRegexp:^[1-9]([0-9]{1,2})?([,.][0-9]{0,2})?$']}
                                    errorMessages={['введите положительное число']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="ageSplit"
                                    name="ageSplit"
                                    label="Список возрастов"
                                    multiline
                                    fullWidth
                                /></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container xs={12} spacing={1}>
                            <Grid item xs={12}><Typography>Разбить по весу</Typography></Grid>
                            <Grid item xs={6}>
                                <TextValidator
                                    id="weightFrom"
                                    name='weightFrom'
                                    label='от'
                                    fullWidth
                                    variant='outlined'

                                    validators={['matchRegexp:^[1-9]([0-9]{1,2})?([,.][0-9]{0,2})?$']}
                                    errorMessages={['введите положительное число']}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment disableTypography position="end">
                                                кг</InputAdornment>,
                                    }}
                                />
                            </Grid><Grid item xs={6}>
                                <TextValidator
                                    id="weightTo"
                                    name='weightTo'
                                    label='до'
                                    variant='outlined'
                                    fullWidth
                                    validators={['matchRegexp:^[1-9]([0-9]{1,2})?([,.][0-9]{0,2})?$']}
                                    errorMessages={['введите положительное число']}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment disableTypography position="end">
                                                кг</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="weightInterval"
                                    name="weightInterval"
                                    label="Интервал веса"
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment disableTypography position="end">
                                                кг</InputAdornment>,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" type="submit" color="primary"
                            sx={{ mb: 2 }}>
                            Создать
                        </Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        );
    }
}
