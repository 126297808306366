import TokenService from "./TokenService";

class Api {
    callApi = (endpoint, config, authenticated) => {

        let token = TokenService.getLocalAccessToken() || null;

        if (authenticated) {

            if (token) {
                if (!config.headers) {
                    config.headers = {};
                }
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            else {
                throw new Error("No token saved!");
            }
        }

        return fetch(endpoint, config);
        /*   .then(response =>
               response.text().then(text => ({ text, response }))
       ).then(({ text, response }) => {
           console.log(text);
           return response;
               if (!response.ok) {
                   return Promise.reject(text)
               }
   
               return text
           })//.catch(err => console.log(err))*/
    }

    setupDefaultConfig = (method, config = {}) => {
        config = config ?? {};
        if (!config.headers) {
            config.headers = {};
        }

        if (!config.headers['Accept']) {
            config.headers['Accept'] = 'application/json';
        }
        if (!config.headers['Content-Type']) {
            config.headers['Content-Type'] = 'application/json';
        }
        if (!config['mode']) {
            config['mode'] = 'cors';
        }
        config['method'] = method;

        return config;
    }

    get(endpoint, config = {}, authenticated = false) {
        config = this.setupDefaultConfig('GET', config);

        return this.callApi(endpoint, config, authenticated);
    }

    post(endpoint, body, config = {}, authenticated = false) {
        config = this.setupDefaultConfig('POST', config);
        
        config['body'] = body;

        return this.callApi(endpoint, config, authenticated);
    }

    put(endpoint, body, config = {}, authenticated = false) {
        config = this.setupDefaultConfig('PUT', config);

        config['body'] = body;

        return this.callApi(endpoint, config, authenticated);
    }

    delete(endpoint, config = {}, authenticated = false) {
        config = this.setupDefaultConfig('DELETE', config);

        return this.callApi(endpoint, config, authenticated);
    }
}

export default new Api();