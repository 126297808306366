import { Home } from "./components/Home";
import { LoginWithRouter } from "./components/Login"
import { Logout } from "./components/Logout"
import { RegisterWithRouter } from "./components/Register";
import { RegisteredWithRouter } from "./components/Registered";
import { Tournaments } from "./components/Tournaments";
import { TournmentSettingsWithRouter } from "./components/admin/TournamentSettings";
import { ApplicationsWithRouter } from "./components/admin/Applications";
import { DivisionsWithRouter } from "./components/admin/Divisions";
import { DivisionApplicationsWithRouter } from "./components/admin/DivisionApplications";
import { BracketsWithRouter } from "./components/admin/Brackets";
import { AllBracketsReportWithRouter } from "./components/admin/AllBracketsReport";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/login',
        element: <LoginWithRouter />
    },
    {
        path: '/logout',
        element: <Logout />
    },
    {
        path: '/tournaments',
        element: <Tournaments />
    },
    {
        path: '/tournaments/:tournament_id/register',
        element: <RegisterWithRouter />
    },
    {
        path: '/tournaments/:tournament_id/registered',
        element: <RegisteredWithRouter />
    },
    {
        path: '/admin/tournaments/:tournament_id/settings',
        element: <TournmentSettingsWithRouter />
    },
    {
        path: '/admin/tournaments/:tournament_id/applications',
        element: <ApplicationsWithRouter />
    },
    {
        path: '/admin/tournaments/:tournament_id/divisions',
        element: <DivisionsWithRouter />
    },
    {
        path: '/admin/tournaments/:tournament_id/divisions/applications',
        element: <DivisionApplicationsWithRouter />
    },
    {
        path: '/admin/tournaments/:tournament_id/divisions/applications/report',
        element: <AllBracketsReportWithRouter />
    },
    {
        path: '/admin/tournaments/:tournament_id/brackets',
        element: <BracketsWithRouter />
    },
];

export default AppRoutes;
