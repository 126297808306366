import React from 'react';
import styled from 'styled-components';
import withRouter from "../withRouter";
import DivisionApplicationService from '../../services/DivisionApplicationService'
import {
    TextField
} from '@mui/material';

const Container = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
  width:100%
`;

const HeaderContainer = styled.th`
  border: 1px solid black;
  border-collapse: collapse;
`;

const DivisionContainer = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  font-weight: 600;
  background-color: lightgray;
`;

const ApplicationContainer = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
`;


export class AllBracketsReport extends React.Component {

    constructor(props) {
        super(props);

        let { tournament_id } = this.props.params;
        this.state = {
            tournamentId: tournament_id,
            search: "",
            loading: true
        };

    }

    componentDidMount() {
        this.populateDivisionsData(this.state.tournamentId);
    }

    async populateDivisionsData(tournamentId) {
        const divisionApplicationMap = await DivisionApplicationService.getDivisionApplications(tournamentId, true);
        this.drawDivisions(divisionApplicationMap);
    }

    drawDivisions(divisionApplicationMap) {
        this.setState({
            ...this.state,
            divisions: divisionApplicationMap.divisions,
            subDivisions: divisionApplicationMap.subDivisions,
            applications: divisionApplicationMap.applications,
            loading: false
        });
    }

    handleChange = (event) => {
        this.setState({ ...this.state, search: event.target.value });
    }


    render() {

        let contents = this.state.loading
            ? <p><em>Зарузка...</em></p>
            : this.renderDivisionApplications(this.state.search);

        return (
            <div>
                <TextField
                    id="search"
                    name='search'
                    label='Поиск...'
                    fullWidth
                    multiline='false'
                    variant='outlined'
                    onChange={this.handleChange}
                ></TextField>
                {contents}
            </div>
        );

    }

    renderDivisionApplications(search) {
        if (search) {
            search = search.toLowerCase();
        }
        return (
            <Container>
                <tr>
                    <HeaderContainer>ФИО</HeaderContainer>
                    <HeaderContainer>Вес</HeaderContainer>
                    <HeaderContainer>Лет</HeaderContainer>
                    <HeaderContainer>Команда, тренер</HeaderContainer>
                    <HeaderContainer>Комментарий</HeaderContainer>
                </tr>
                {Object.keys(this.state.divisions).map(divisionId => {
                    const division = this.state.divisions[divisionId];

                    const subDivisions = division.subDivisionIds.map(
                        subDivisionId => this.state.subDivisions[subDivisionId],
                    );

                    return subDivisions.map((subDivision, index) => {
                        const applications = subDivision.applicationIds.map(
                            applicationId => this.state.applications[applicationId],
                        );

                        if (applications.length > 0 && (!search ||
                            division.title.toLowerCase().includes(search) ||
                            applications.some(a =>
                                a.fio.toLowerCase().includes(search) ||
                                a.team.toLowerCase().includes(search) ||
                                a.comment.toLowerCase().includes(search)
                            ))) {

                            return (<tbody>
                                <tr><DivisionContainer colSpan="5">{division.title} : {subDivision.name}</DivisionContainer></tr>
                                {
                                    applications.map((application, index) => {
                                        return (<tr>
                                            <ApplicationContainer width="400">{application.fio}</ApplicationContainer>
                                            <ApplicationContainer>{application.weight}</ApplicationContainer>
                                            <ApplicationContainer>{application.age}</ApplicationContainer>
                                            <ApplicationContainer>{application.team}</ApplicationContainer>
                                            <ApplicationContainer>{application.comment}</ApplicationContainer>
                                        </tr>)
                                    })
                                }

                            </tbody>
                            );
                        }
                    });




                })}
            </Container>);
    }


}

export const AllBracketsReportWithRouter = withRouter(AllBracketsReport);

