import React, { Component } from 'react';
import TokenService from '../services/TokenService';

export class Tournaments extends Component {
    static displayName = Tournaments.name;

    constructor(props) {
        super(props);
        this.state = { tournaments: [], loading: true };
    }

    componentDidMount() {
        this.populateTournamentData();
    }

    static renderTournamentTable(tournaments) {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return (
            <table className="table table-striped" aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Дата проведения</th>
                        <th>Название</th>
                        <th>Регистрация до</th>
                        <th>Регистрация открыта</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tournaments.map(tournament =>
                        <tr key={tournament.id}>
                            <td>{new Date(Date.parse(tournament.date)).toLocaleDateString(undefined, options).replace(".", "")}</td>
                            <td>{tournament.name}</td>
                            <td>{new Date(Date.parse(tournament.registrationDeadline)).toLocaleDateString(undefined, options).replace(".", "")}</td>
                            <td>{tournament.registrationOpened ? "да" : "нет"}</td>
                            <td><a href={"/tournaments/" + tournament.id + "/register"}>регистрация</a></td>
                            {TokenService.getUser() ?
                                (<td><a href={"/admin/tournaments/" + tournament.id + "/settings"}>настройки</a></td>) :
                                (<td/>)
                            }
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Tournaments.renderTournamentTable(this.state.tournaments);

        return (
            <div>
                <h1 id="tableLabel">Турниры</h1>
                {contents}
            </div>
        );
    }

    async populateTournamentData() {
        const response = await fetch('api/tournaments/active');
        const data = await response.json();
        this.setState({ tournaments: data, loading: false });
    }
}