import Api from './Api'
class DivisionApplicationService {
    async getDivisionApplications(tournamentId, isPrivate) {
        const divisionsResponse = await Api.get('api/tournaments/' + (isPrivate ? 'private/' : '') + tournamentId + '/divisions', {}, !isPrivate);
        const divisionsData = await divisionsResponse.json();
        const applicationsResponse = await Api.get('api/tournaments/' + (isPrivate ? 'private/' : '') + tournamentId + '/applications', {}, !isPrivate);
        const applicaitonsData = await applicationsResponse.json();

        return this.getDivisionApplicationMap(divisionsData, applicaitonsData);
    }

    getDivisionApplicationMap(divisionsData, applicaitonsData){
        let applicationsMap = {};
        let divisionMap = {};
        let allIds = [];
        for (var i in applicaitonsData) {
            var a = applicaitonsData[i];
            allIds.push(a.id);
            applicationsMap[a.id] = a;
        }

        let subDivisionMap = {};
        let defaultSubDivision = { id: "0", divisionId: "0", name: "", applicationIds: allIds };
        subDivisionMap[defaultSubDivision.id] = defaultSubDivision;
        divisionMap["0"] = {
            id: "0", title: "Без группы", subDivisionIds: ["0"]
        };
        for (var i in divisionsData) {
            var d = divisionsData[i];
            var subDivisionIds = [];
            for (var j in d.subDivisions) {
                var sd = d.subDivisions[j];
                var applicationIds = [];
                if (sd.applications != null) {
                    for (var a in sd.applications) {
                        var applicationId = sd.applications[a].applicationId;
                        const applicationIndex = allIds.indexOf(applicationId);
                        if (applicationIndex > -1) {
                            allIds.splice(applicationIndex, 1);
                        }
                        applicationIds.push(applicationId);
                    }
                }
                var subDivision = { id: sd.id, divisionId: sd.divisionId, name: sd.name, applicationIds: applicationIds };
                subDivisionMap[sd.id] = subDivision;
                subDivisionIds.push(sd.id);
            }
            divisionMap[d.id] = {
                id: d.id, title: d.name, subDivisionIds: subDivisionIds
            };
        }

        return { divisions: divisionMap, subDivisions: subDivisionMap, applications: applicationsMap };

    }

}

export default new DivisionApplicationService();