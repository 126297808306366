import React, { Component } from 'react';
import {
    Button, Stack, Box, Typography,
    Snackbar, Alert
} from '@mui/material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Api from '../services/Api';
import TokenService from '../services/TokenService';
import withRouter from "./withRouter";

class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);
        this.state = {
            formData: {
                "email": "",
                "password": ""
            }
        }
    }

    handleChange = (event) => {
        this.handleValueChange(event.target.name, event.target.value);
    }

    handleValueChange = (name, value) => {
        const { formData } = this.state;
        formData[name] = value;
        this.setState({ formData });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        var data = {
            email: e.target.email.value,
            password: e.target.password.value,
        };
        var body = JSON.stringify(data);
        // const history = createBrowserHistory();
        Api.post('api/auth/login',
            body, {}, false)
            .then((response) => {
                response.json().then((user) => {
                    TokenService.setUser({ accessToken: user.token, username: user.username, email: user.email });
                    this.props.navigate("/tournaments");
                });
            })

        //TokenService.setUser();
    }

    render() {

        const { formData } = this.state;
        return (
            <div>
                <h1 id="tableLabel">Вход</h1>
                <ValidatorForm
                    ref="form" noValidate instantValidate={false}
                    onSubmit={(e) => this.handleSubmit(e)}                >
                    <Box sx={{ width: 400 }}>
                        <Stack spacing={2}>
                            <TextValidator
                                id="email"
                                name="email"
                                autoComplete="email"
                                label="Email"
                                required
                                fullWidth
                                variant='outlined'
                                onChange={this.handleChange}
                                value={formData.email}
                                validators={['required']}
                                errorMessages={['заполните обязательное поле']}
                            />
                            <TextValidator
                                id="password"
                                name="password"
                                type="password"
                                label="Пароль"
                                required
                                fullWidth
                                variant='outlined'
                                onChange={this.handleChange}
                                value={formData.password}
                                validators={['required']}
                                errorMessages={['заполните обязательное поле']}
                            />
                            <Button variant="contained" type="submit" color="primary" fullWidth
                                sx={{ mb: 2 }}>
                                Войти
                            </Button>
                        </Stack>
                    </Box>
                </ValidatorForm>
            </div>
        );
    }
}
export const LoginWithRouter = withRouter(Login);