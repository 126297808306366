import React, { Component } from 'react';
import {
    Button, TextField, MenuItem, FormControl, Box, Grid,
    InputAdornment, FormLabel, RadioGroup, Radio, FormControlLabel, Typography,
    Snackbar, Alert
} from '@mui/material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import withRouter from "../withRouter";
import TournamentNavMenu from './TournamentNavMenu'
import Api from '../../services/Api';

export class Brackets extends Component {
    static displayName = Brackets.name;

    constructor(props) {
        super(props);
        let { tournament_id } = this.props.params;
        this.state = {
            tournamentId: tournament_id,
            loading: false
        };
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        try {

            const { tournamentId } = this.state;

            var data = {
                "matQty": 1
            };
            var body = JSON.stringify(data);
            return Api.post('api/tournaments/' + tournamentId + '/brackets/generate',
                body, {}, true).then((response) => {
                    console.log(response);
                    return response;
                });


        }
        catch (error) {

            /* Log error to console */
            console.error(error)
        }

    }

    render() {
        let contents = this.state.loading
            ? <p><em>Зарузка...</em></p>
            : this.renderBracketsForm();

        return (
            <div>
                <TournamentNavMenu />
                {contents}
            </div>
        );
    }

    renderBracketsForm = () => {


        const options = { year: "numeric", month: "long", day: "numeric" };
        const { formData } = this.state;
        return (
            <Box sx={{ mt: 3 }}>
                <ValidatorForm
                    ref="form" noValidate instantValidate={false} onSubmit={(e) => {
                        e.preventDefault();

                        this.handleSubmit(e).then((data) => {
                            if (data.status != 200) {
                                this.setState({ snackbarOpen: true, snackbarSeverity: "error", snackbarMessage: data.statusText });
                            } else {
                                this.setState({ snackbarOpen: true, snackbarSeverity: "success", snackbarMessage: "Сетки созданы" });
                            }
                        })
                        // this.setState({ snackbarOpen: true, snackbarMessage: "Заявка на регистрацию отправлена" });
                    }} >
                    <Snackbar
                        open={this.state.snackbarOpen}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        onClose={this.handleClose}
                    >
                        <Alert onClose={this.handleClose} severity={this.state.snackbarSeverity}>{this.state.snackbarMessage}</Alert>
                    </Snackbar>
                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={12}>
                            <Button variant="contained" type="submit" color="primary" fullWidth
                                sx={{ mb: 2 }}>
                                Создать
                            </Button>

                        </Grid>

                    </Grid>

                </ValidatorForm>
            </Box>
        );
    }

}

export const BracketsWithRouter = withRouter(Brackets);