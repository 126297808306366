import React, { Component } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';
import {
    matchPath,
    useLocation,
} from 'react-router-dom';


function useRouteMatch(patterns) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }

    return null;
}

function TournamentNavMenu(props) {
    const routeMatch = useRouteMatch(['/admin/tournaments/:id/settings',
        '/admin/tournaments/:id/applications',
        '/admin/tournaments/:id/divisions',
        '/admin/tournaments/:id/divisions/applications',
        '/admin/tournaments/:id/brackets',]);
    const currentTab = routeMatch?.pathname;
    const tournamentId = routeMatch.params.id;

    return (
        <Tabs value={currentTab} sx={{mb:0.5}}>
            <Tab label="Настройки" value={`/admin/tournaments/${tournamentId}/settings`} to={`/admin/tournaments/${tournamentId}/settings`} component={Link} />
            <Tab label="Заявки" value={`/admin/tournaments/${tournamentId}/applications`} to={`/admin/tournaments/${tournamentId}/applications`} component={Link} />
            <Tab label="Группы" value={`/admin/tournaments/${tournamentId}/divisions`} to={`/admin/tournaments/${tournamentId}/divisions`} component={Link} />
            <Tab label="Заявки по группам" value={`/admin/tournaments/${tournamentId}/divisions/applications`} to={`/admin/tournaments/${tournamentId}/divisions/applications`} component={Link} />
            <Tab label="Сетки" value={`/admin/tournaments/${tournamentId}/brackets`} to={`/admin/tournaments/${tournamentId}/brackets`} component={Link} />
        </Tabs>
    );
}

export default TournamentNavMenu;
