import React, { Component } from 'react';
import withRouter from "../withRouter";
import TournamentNavMenu from './TournamentNavMenu'

export class Divisions extends Component {
    static displayName = Divisions.name;

    constructor(props) {
        super(props);
        let { tournament_id } = this.props.params;
        this.state = {
            tournamentId: tournament_id,
            loading: false
        };
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Зарузка...</em></p>
            : <div>Coming soon</div>;

        return (
            <div>
                <TournamentNavMenu />
                {contents}
            </div>
        );
    }
}

export const DivisionsWithRouter = withRouter(Divisions);