import React, { Component } from 'react';
import withRouter from "../withRouter";
import Api from '../../services/Api';
import { DataGrid, GridColDef, gridClasses, ruRU, GridToolbar } from '@mui/x-data-grid';
import { alpha, styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import SplitButton from '../../controls/SplitButton';
import TournamentNavMenu from './TournamentNavMenu'

export class Applications extends Component {
    static displayName = Applications.name;

    constructor(props) {
        super(props);
        let { tournament_id } = this.props.params;
        this.state = {
            tournamentId: tournament_id,
            applications: [],
            loading: true
        };
    }

    selectedApplicationIds = [];

    componentDidMount() {
        this.populateApplicationsData(this.state.tournamentId);
    }

    onSelectionModelChange = (ids) => {
        this.selectedApplicationIds = ids;
    }

    onPaymentClick = (e, selectedIndex) => {
        const ids = this.selectedApplicationIds;
        const paid = selectedIndex == 0;
        Api.put('api/tournamentapplications/paid',
            JSON.stringify({ applicationIds: ids, paid: paid }), {}, true)
            .then((response) => {
                let applications = this.state.applications;
                applications.filter(a => ids.indexOf(a.id) > -1).forEach(a => { a.paymentConfirmed = paid; });
                this.setState({ ...this.state, applications: applications });
            })
    }

    renderApplicationsTable(applications) {

        const ODD_OPACITY = 0.2;

        const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
            [`& .${gridClasses.row}.even`]: {
                backgroundColor: theme.palette.grey[200],
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                    '@media (hover: none)': {
                        backgroundColor: 'transparent',
                    },
                },
                '&.Mui-selected': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                    '&:hover, &.Mui-hovered': {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            ODD_OPACITY +
                            theme.palette.action.selectedOpacity +
                            theme.palette.action.hoverOpacity,
                        ),
                        // Reset on touch devices, it doesn't add specificity
                        '@media (hover: none)': {
                            backgroundColor: alpha(
                                theme.palette.primary.main,
                                ODD_OPACITY + theme.palette.action.selectedOpacity,
                            ),
                        },
                    },
                },
            },
        }));

        const columns: GridColDef[] = [
            {
                field: 'fio',
                headerName: 'Фамилия и Имя',
                width: 150,
                editable: false,
            },
            {
                field: 'sex',
                headerName: 'Пол',
                width: 100,
                editable: false,
            }
            ,
            {
                field: 'beltName',
                headerName: 'Пояс',
                width: 100,
                editable: false,
            },
            {
                field: 'age',
                headerName: 'Возраст',
                type: 'number',
                width: 100,
                editable: false,
            },
            {
                field: 'weight',
                headerName: 'Вес',
                type: 'number',
                width: 100,
                editable: false,
            },
            {
                field: 'phone',
                headerName: 'Телефон',
                width: 100,
                editable: false,
            },
            {
                field: 'email',
                headerName: 'Email',
                width: 100,
                editable: false,
            },
            {
                field: 'comment',
                headerName: 'Комментарий',
                width: 100,
                editable: false,
            },
            {
                field: 'team',
                headerName: 'Команда и фамилия тренера',
                width: 100,
                editable: false,
            },
            {
                field: 'createdAt',
                headerName: 'Дата регистрации',
                width: 100,
                editable: false,
            },
            {
                field: 'paymentConfirmed',
                headerName: 'Оплата подтверждена',
                type: 'boolean',
                width: 100,
                editable: false,
            }
            ,
            {
                field: 'files',
                headerName: 'Чек',
                width: 100,
                editable: false,
                valueFormatter: (params) => params.value ? params.value.map((v, i) => (i > 0 ? ", " : "") + v.publicUrl) : "-",
                renderCell: (params) =>
                    params.value ? (<span>{params.value.map((v, i) => <span>{i > 0 ? <br /> : <span />}<Link href={`${v.publicUrl}`} target='_blank'>{v.fileName}</Link></span>)}</span>)
                        : (<span>-</span>)

            }
        ]

        const theme = createTheme(
            {
            },
            ruRU,
        );

        return (
            <Box>
                <SplitButton id="btnPay" name="btnPay"
                    options={["подтвердить оплату", "отменить подтеверждение оплаты"]}
                    onClick={this.onPaymentClick} />
                <div style={{ height: "70vh", width: '100%' }}>
                    <ThemeProvider theme={theme}>
                        <StripedDataGrid
                            rows={applications}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 50,
                                    },
                                },
                            }}
                            onRowSelectionModelChange={this.onSelectionModelChange}
                            pageSizeOptions={[10, 50, 100]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            slots={{ toolbar: GridToolbar }}

                        />
                    </ThemeProvider>
                </div>
            </Box >
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Зарузка...</em></p>
            : this.renderApplicationsTable(this.state.applications);

        return (
            <div>
                <TournamentNavMenu />
                {contents}
            </div>
        );
    }

    async populateApplicationsData(tournamentId) {
        const response = await Api.get('api/tournaments/' + tournamentId + '/applications', {}, true);
        const data = await response.json();
        this.setState({ applications: data, loading: false });
    }
}

export const ApplicationsWithRouter = withRouter(Applications);