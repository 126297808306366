import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import ApplicationCard from './ApplicationCard';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import { pink, blue } from '@mui/material/colors';
import Api from '../../services/Api';
import Bracket from './Bracket'

const Container = styled.div`
  margin: 2px;
  border: 0px;
  display: flex;
  flex-direction: column;
`;

const SubContainer = styled.div`
  padding: 1px;
  border: 0px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 100px;
`;

const CardsContainer = styled.div`
  padding: 0px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 220px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100px;
`;

const Title = styled.h5`
  padding: 4px;
  height: 75px;
  font-weight: 600;
    color: ${props => (props.isFemale ? 'black' : 'black')};
`;

const SubTitle = styled.h6`
  padding: 4px;
  font-weight: 400;
`;

const ApplicationsList = styled.div`
  padding: 4px;
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? 'skyblue' : 'white')};
  flex-grow: 1;
  min-height: 100px;
`;

export default class ApplicationsColumn extends React.Component {

    constructor(props) {
        super(props);
        // const classes = useStyles();
        // const [anchorEl, setAnchorEl] = React.useState(null);
        this.state = {
            anchorEl: null,
            open: false,
            id: undefined,
            tournamentId: props.tournamentId,
            tournamentPrivateId: props.tournamentPrivateId
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);

        // const open = Boolean(anchorEl);
        // const id = open ? "simple-popover" : undefined;
    }

    handleClick(event) {
        this.setState({ anchorEl: event.currentTarget, open: Boolean(event.currentTarget), id: "simple-popover" });
    }

    autoAssignDivision(event) {

        Api.post('api/tournaments/' + this.state.tournamentId + '/subdivisions/auto',
            {}, {}, true)
            .then((response) => {
                response.json().then((divisions) => {
                    this.props.onDivisionChanged(divisions);
                });
            })
    }

    handleClose(event) {
        this.setState({ anchorEl: event.currentTarget, open: false, id: undefined });
    }

    async handleBracket(subdivisionId) {
        const response = await Api.get('api/subdivisions/' + subdivisionId + '/bracket', {}, true);
        const data = await response.json();
        this.setState({ bracket: data.status === 404 ? null : data, showBracketForSubdivisionId: subdivisionId });
    }

    handleCloseBracket() {
        this.setState({ showBracketForSubdivisionId: null });
    }

    render() {
        const ITEM_HEIGHT = 48;
        return (
            <Container>
                <Title isFemale={true}>{this.props.division.title}
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={this.state.open ? 'long-menu' : undefined}
                        aria-expanded={this.state.open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Title>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onClose={this.handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '25ch',
                        },
                    }}
                >
                    {this.props.division.id == "0" ?
                        <>
                            <MenuItem key="auto" onClick={(e) => { this.autoAssignDivision(e); this.handleClose(e); }}>
                                распределить
                            </MenuItem>
                            <MenuItem key="auto-groups" onClick={(e) => { this.props.onGenerateDivisions(); this.handleClose(e); }}>
                                создать группы
                            </MenuItem>
                        </>
                        :
                        <MenuItem key="subgroup" onClick={this.handleClose}>
                            добавить подгруппу
                        </MenuItem>
                    }

                </Menu>
                <SubContainer>
                    {this.props.subDivisions.map((subDivision, index) => {
                        const applications = subDivision.applicationIds.map(
                            applicationId => this.props.applications[applicationId],
                        );

                        return (
                            <CardsContainer>
                                {this.state.showBracketForSubdivisionId && this.state.showBracketForSubdivisionId === subDivision.id ?
                                    <Dialog
                                        fullScreen
                                        open={this.state.showBracketForSubdivisionId}
                                        onClose={() => this.handleCloseBracket()}
                                    >
                                        <AppBar sx={{ position: 'relative' }}>
                                            <Toolbar>
                                                <IconButton
                                                    edge="start"
                                                    color="inherit"
                                                    onClick={() => this.handleCloseBracket()}
                                                    aria-label="close"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                                    Сетка {this.props.division.title + " : " + subDivision.name}
                                                </Typography>
                                            </Toolbar>
                                        </AppBar>
                                        {this.state.bracket ?
                                            <Bracket applications={this.state.bracket.applications}
                                                matches={this.state.bracket.matches}
                                                subDivisionId={this.state.showBracketForSubdivisionId}></Bracket> :
                                            <span>сетка не сформирована</span>}
                                    </Dialog> : <span />
                                }
                                {subDivision.id == "0" ?
                                    <span>сетки
                                        (<Link target="_blank"
                                            variant="body2" href={"api/tournaments/" + this.state.tournamentId + "/brackets/download-pdf-zip"}>zip</Link> |
                                        <Link variant="body2" href={"/admin/tournaments/" + this.state.tournamentPrivateId + "/divisions/applications/report"} target="_blank">отчет</Link>)
                                    </span>
                                    :
                                    <SubTitle>{subDivision.name}
                                        (<Link
                                            component="button"
                                            variant="body2"
                                            onClick={() => this.handleBracket(subDivision.id)}
                                        >
                                            сетка
                                        </Link> | <Link target="_blank"
                                            variant="body2" href={"api/subdivisions/" + subDivision.id + "/bracket/download-pdf"}>pdf</Link>)

                                    </SubTitle>
                                }
                                <Droppable droppableId={subDivision.id}>
                                    {(provided, snapshot) => (
                                        <ApplicationsList
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            isDraggingOver={snapshot.isDraggingOver}
                                        >
                                            {applications.map((application, index) => (
                                                <ApplicationCard key={application.id} application={application} subdivisionId={subDivision.id}
                                                    index={index} onDelete={this.props.onCardDelete} />
                                            ))}
                                            {provided.placeholder}
                                        </ApplicationsList>
                                    )}
                                </Droppable>
                            </CardsContainer>
                        )
                    })}
                </SubContainer>
            </Container>
        );
    }
}
