import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import withRouter from "../withRouter";
import { DragDropContext } from 'react-beautiful-dnd';
import styled from 'styled-components';
import ApplicationsColumn from './ApplicationsColumn';
import Api from '../../services/Api';
import DivisionApplicationService from '../../services/DivisionApplicationService'
import TournamentNavMenu from './TournamentNavMenu'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import GroupsAutoGenerator from './GroupsAutoGenerator'


const Container = styled.div`
  display: flex;
`;

const ContainerLinks = styled.div`
  width: 100%;
`;

//  overflow-x: scroll;
export class DivisionApplications extends Component {
    static displayName = DivisionApplications.name;

    constructor(props) {
        super(props);
        let { tournament_id } = this.props.params;

        this.state = {
            tournamentId: tournament_id,
            divisions: {},
            applications: {},
            loading: true
        };
    }

    componentDidMount() {
        this.populateDivisionsData(this.state.tournamentId);
    }

    async populateDivisionsData(tournamentId) {
        const divisionApplicationMap = await DivisionApplicationService.getDivisionApplications(tournamentId, false);
        const privateIdResponse = await Api.get('api/tournaments/' + tournamentId + '/private-id', {}, true);
        const privateId = await privateIdResponse.json();

        this.setState({
            ...this.state,
            divisions: divisionApplicationMap.divisions,
            subDivisions: divisionApplicationMap.subDivisions,
            applications: divisionApplicationMap.applications,
            privateId: privateId,
            loading: false
        });
    }

    drawDivisions(divisionApplicationMap) {
        this.setState({
            ...this.state,
            divisions: divisionApplicationMap.divisions,
            subDivisions: divisionApplicationMap.subDivisions,
            applications: divisionApplicationMap.applications,
        });
    }

    moveCard(sourceId, sourceIndex, destinationId, destinationIndex, applicationId) {
        const start = this.state.subDivisions[sourceId];
        const finish = this.state.subDivisions[destinationId];

        if (start.id === finish.id) {
            const newApplicationIds = Array.from(start.applicationIds);
            newApplicationIds.splice(sourceIndex, 1);
            newApplicationIds.splice(destinationIndex, 0, applicationId);

            const newColumn = {
                ...start,
                applicationIds: newApplicationIds,
            };

            const newState = {
                ...this.state,
                subDivisions: {
                    ...this.state.subDivisions,
                    [newColumn.id]: newColumn,
                },
            };

            this.postMoveApplication(sourceId, destinationId, applicationId, destinationIndex);
            this.setState(newState);
            return;
        }

        // Moving from one list to another
        const startApplicationIds = Array.from(start.applicationIds);
        startApplicationIds.splice(sourceIndex, 1);
        const newStart = {
            ...start,
            applicationIds: startApplicationIds,
        };

        const finishApplicationIds = Array.from(finish.applicationIds);
        finishApplicationIds.splice(destinationIndex, 0, applicationId);
        const newFinish = {
            ...finish,
            applicationIds: finishApplicationIds,
        };

        const newState = {
            ...this.state,
            subDivisions: {
                ...this.state.subDivisions,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish,
            },
        };
        this.postMoveApplication(sourceId, destinationId, applicationId, destinationIndex);
        this.setState(newState);
    }

    postMoveApplication(fromSubdivisionId, toSubdivisionId, applicationId, index) {
        if (toSubdivisionId == "0") {
            Api.delete('api/subdivisions/' + fromSubdivisionId + '/applications/' + applicationId,
                {}, {}, true)
                .then((response) => {
                    response.json().then((divisions) => {
                        // this.props.onDivisionChanged(divisions);
                    });
                })
        }
        else {
            Api.post('api/subdivisions/' + toSubdivisionId + '/applications/' + applicationId,
                JSON.stringify({ "fromSubdivisionId": fromSubdivisionId == "0" ? null : fromSubdivisionId, "toPosition": index }), {}, true)
                .then((response) => {
                    response.json().then((divisions) => {
                        // this.props.onDivisionChanged(divisions);
                    });
                })
        }
    }

    onDragEnd = result => {

        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        this.moveCard(source.droppableId, source.index, destination.droppableId, destination.index, draggableId);
    }


    onDivisionChanged = (divisions) => {
        this.drawDivisions(DivisionApplicationService.getDivisionApplicationMap(divisions, this.state.applications));
    }


    onCardDelete = (subdivisionId, applicationId, applicationIndex) => {
        this.moveCard(subdivisionId, applicationIndex, "0", 0, applicationId);
    }

    handleOpenAutoGenerateDivisions = () =>  {
        this.setState({ showAutoGenerateDivisions: true });
    }

    handleCloseAutoGenerateDivisions = () => {
        this.setState({ showAutoGenerateDivisions: null, loading: true });
        this.populateDivisionsData(this.state.tournamentId);
    }

    render() {

        let contents = this.state.loading
            ? <p><em>Зарузка...</em></p>
            : this.renderDivisionApplications();


        return (
            <div>
                <TournamentNavMenu />
                {contents}
            </div>
        );



    }

    renderDivisionApplications() {
        return (<DragDropContext onDragEnd={this.onDragEnd}>

            <Dialog
                fullScreen
                open={this.state.showAutoGenerateDivisions}
                onClose={() => this.handleCloseAutoGenerateDivisions()}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => this.handleCloseAutoGenerateDivisions()}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Создать группы
                        </Typography>
                    </Toolbar>
                </AppBar>
                <GroupsAutoGenerator tournamentId={this.state.tournamentId} onDivisionsGenerated={this.handleCloseAutoGenerateDivisions }></GroupsAutoGenerator>
            </Dialog>

            <Container>

                {Object.keys(this.state.divisions).map(divisionId => {
                    const division = this.state.divisions[divisionId];

                    const subDivisions = division.subDivisionIds.map(
                        subDivisionId => this.state.subDivisions[subDivisionId],
                    );

                    return <ApplicationsColumn key={division.id} tournamentId={this.state.tournamentId} division={division}
                        subDivisions={subDivisions} applications={this.state.applications} tournamentPrivateId={this.state.privateId}
                        onDivisionChanged={this.onDivisionChanged} onGenerateDivisions={this.handleOpenAutoGenerateDivisions}
                        onCardDelete={this.onCardDelete} />;
                })}
            </Container>
        </DragDropContext>);
    }
}

export const DivisionApplicationsWithRouter = withRouter(DivisionApplications);