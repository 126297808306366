import React from 'react';
import { Select, FormHelperText, FormControl, InputLabel } from '@mui/material';
/* eslint-enable */
import { ValidatorComponent } from 'react-form-validator-core';

export default class SelectValidator extends ValidatorComponent {
    renderValidatorComponent() {
        /* eslint-disable no-unused-vars */
        const {
            error,
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            containerProps,
            ...rest
        } = this.props;
        const { isValid } = this.state;
        return (
            <FormControl fullWidth>
                <InputLabel id={this.props.id + "-label"} required error={!isValid || error}>{this.props.label}</InputLabel>
                <Select
                    {...rest}
                    error={!isValid || error}>
                    {this.props.children}
                </Select>
                {!isValid || error ?
                    <FormHelperText error="true">{(!isValid && this.getErrorMessageProxy()) || helperText}</FormHelperText> :
                    <span />
                }
            </FormControl>
        );
    }

    getErrorMessageProxy() {
        let m = this.getErrorMessage();
        return m;
    }
}