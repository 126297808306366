import React, { Component } from 'react';
import {
    Button, TextField, MenuItem, FormControl, Box, Grid,
    InputAdornment, FormLabel, RadioGroup, Radio, FormControlLabel, Typography,
    Snackbar, Alert, LinearProgress
} from '@mui/material';

import PhoneNumberValidator from './PhoneNumberValidator';
import FileUploadValidator from './FileUploadValidator';
import DatePickerValidator from './DatePickerValidator';
import SelectValidator from './SelectValidator';
import withRouter from "./withRouter";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Api from '../services/Api';


class Register extends Component {
    static displayName = Register.name;

    constructor(props) {
        super(props);
        let { tournament_id } = this.props.params;
        this.state = {
            belts: [],
            loading: true,
            isSaving: false,
            tournamentId: tournament_id,
            tournamentInfo: null,
            snackbarOpen: false,
            snackbarMessage: "",
            snackbarSeverity: "",
            files: [],
            formData: {
                fio: "",
                sex: "",
                email: "",
                phone: "",
                birthday: null,
                weight: "",
                comment: ""
            }
        };


    }

    componentDidMount() {
        this.populateTournamentData(this.state.tournamentId);
    }

    handleChange = (event) => {
        this.handleValueChange(event.target.name, event.target.value);
    }

    handlePhoneChange = (event) => {
        if (event === '+')
            event = '';
        this.handleValueChange('phone', event);
    }

    handleValueChange = (name, value) => {
        const { formData } = this.state;
        formData[name] = value;
        this.setState({ formData });
    }

    uploadFiles = async () => {
        // Create a form and post it to server
        let formData = new FormData()
        formData.append("file", this.state.files[0]);
        formData.append("folder", "checks")

        const uploadResponse = await fetch("/api/files", {
            method: "POST",
            body: formData
        });
        const uploadData = await uploadResponse.json();


        return [{ id: uploadData.id, fileName: uploadData.initialFileName, publicUrl: uploadData.publicUrl }];
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        const files = await this.uploadFiles();
        const { formData, tournamentInfo } = this.state;
        const { birthday } = formData;
        try {

            var data = {
                fio: e.target.fio.value,
                sex: e.target.sex.value,
                birthday: birthday,
                belt: e.target.belt.value,
                weight: e.target.weight.value,
                phone: e.target.phone.value,
                email: e.target.email.value,
                team: e.target.team.value,
                comment: e.target.comment.value,
                checks: files
            };
            var body = JSON.stringify(data);
            return Api.post('api/tournaments/' + tournamentInfo.id + '/register',
                body, {}, false).then((response) => {
                    console.log(response);
                    this.props.navigate("/tournaments/" + tournamentInfo.id + "/registered");
                    //return response;
                });


        }
        catch (error) {

            /* Log error to console */
            console.error(error)
        }

    }

    handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            snackbarOpen: false
        });
    };

    handleFilesChange = (files) => {
        this.setState({ files: files });
    };

    renderRegistrationForm = (belts, tournamentInfo) => {


        const options = { year: "numeric", month: "long", day: "numeric" };
        const { formData, isSaving } = this.state;
        let registrationDeadline = Date.parse(tournamentInfo.registrationDeadline);
        let registrationContent = !tournamentInfo.registrationOpened || (registrationDeadline && registrationDeadline < new Date()) ?
            (<Grid container xs={12} sm={12} spacing={3} >
                <Grid item xs={12} sm={12}><Typography variant="h6" gutterBottom>Регистрация закрыта</Typography></Grid>
            </Grid>) :
            (<Grid container xs={12} sm={12} spacing={3} >
                <Grid item xs={12} sm={12}><Typography variant="h6" gutterBottom>Данные об участнике</Typography></Grid>
                <Grid item xs={12} sm={12}>
                    <TextValidator
                        id="fio"
                        name="fio"
                        autoComplete="fio"
                        label="Фамилия и имя участника"
                        required
                        fullWidth
                        autoFocus
                        onChange={this.handleChange}
                        value={formData.fio}
                        validators={['required']}
                        errorMessages={['заполните обязательное поле']}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControl>
                        <FormLabel id="sex-group-label" required>Пол</FormLabel>
                        <RadioGroup
                            aria-labelledby="sex-group-label"
                            defaultValue="male"
                            name="sex"
                            row
                            id="sex"
                        >
                            <FormControlLabel value="male" control={<Radio />} label="Мужской" />
                            <FormControlLabel value="female" control={<Radio />} label="Женский" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <DatePickerValidator
                        id="birthdayContainer"
                        name="birthdayContainer"
                        label="Дата рождения участника *"
                        required
                        fullWidth
                        disableFuture
                        validators={['required']}
                        errorMessages={['заполните обязательное поле']}
                        value={formData.birthday}
                        onChange={(newValue) => {
                            var d = new Date(newValue);
                            this.handleValueChange("birthday", d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate());
                        }} />
                </Grid>
                <Grid item xs={12} sm={12}>

                    <SelectValidator fullWidth
                        labelId="belt-label"
                        id="belt"
                        name="belt"
                        label="Пояс"
                        validators={['required']}
                        errorMessages={['заполните обязательное поле']}
                        value={formData.belt}
                        onChange={this.handleChange}
                    >
                        {belts.map(belt =>
                            <MenuItem value={belt.id}>{belt.name}</MenuItem>
                        )}
                    </SelectValidator>

                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextValidator
                        id="weight"
                        name='weight'
                        label='Вес участника в рашгарде и шортах'
                        required
                        fullWidth
                        variant='outlined'
                        onChange={this.handleChange}
                        value={formData.weight}
                        validators={['required', 'matchRegexp:^[1-9]([0-9]{1,2})?([,.][0-9]{0,2})?$']}
                        errorMessages={['заполните обязательное поле', 'введите положительное число']}
                        InputProps={{
                            endAdornment:
                                <InputAdornment disableTypography position="end">
                                    кг</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <PhoneNumberValidator
                        defaultCountry={'ru'}
                        id="phone"
                        name="phone"
                        label="Контактный номер участника"
                        required
                        variant='outlined'
                        fullWidth
                        onChange={this.handlePhoneChange}
                        value={formData.phone}
                        validators={['required']}
                        errorMessages={['заполните обязательное поле']}
                    />
                    <Typography variant="caption" display="block" gutterBottom>для детей - номер родителя</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextValidator
                        id="email"
                        name="email"
                        autoComplete="email"
                        label="Email участника"
                        required
                        fullWidth
                        variant='outlined'
                        onChange={this.handleChange}
                        value={formData.email}
                        validators={['required', 'isEmail']}
                        errorMessages={['заполните обязательное поле', 'заполните поле в формате email ([имя]@[домен])']}
                    />
                    <Typography variant="caption" display="block" gutterBottom>для детей - email родителя</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextValidator
                        id="team"
                        name="team"
                        autoComplete="team"
                        label="Команда и фамилия тренера"
                        required
                        fullWidth
                        onChange={this.handleChange}
                        value={formData.team}
                        validators={['required']}
                        errorMessages={['заполните обязательное поле']}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        id="comment"
                        name="comment"
                        label="Комментарий"
                        multiline
                        fullWidth
                        onChange={this.handleChange}
                        value={formData.comment}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FileUploadValidator
                        id="file"
                        title="Загрузите подтверждение оплаты *"
                        header="Перетащите сюда файлы"
                        leftLabel="или"
                        rightLabel="для выбора файлов"
                        maxFileSize="0.5"
                        buttonLabel="нажмите"
                        buttonRemoveLabel="очистить"
                        multiFile={false}
                        onFilesChange={this.handleFilesChange}
                        allowedExtensions={['jpg', 'jpeg', 'png', 'gif', 'pdf']}
                        onContextReady={(context) => { }}
                        BannerProps={{ elevation: 0, variant: "outlined" }}
                        showPlaceholderImage={false}
                        validators={['required']}
                        errorMessages={['загрузите подтверждение оплаты']}
                        value={this.state.files.length > 0 ? "file" : ""}
                    />
                    
                </Grid>
                {
                    isSaving ?
                    (<Grid item xs={12} sm={12}>
                        <LinearProgress /> 
                        </Grid>) : <span />
                }
                <Grid item xs={12} sm={12}>
                    <Button variant="contained" type="submit" color="primary" fullWidth disabled={isSaving}
                        sx={{ mb: 2 }}>
                        Зарегистрироваться
                    </Button>
                    <Typography variant="subtitle1" display="block" gutterBottom>Нажимая на кнопку, вы даете согласие на обработку персональных данных</Typography>

                </Grid>
            </Grid>)
        return (
            <Box sx={{ mt: 3 }}>
                <ValidatorForm
                    ref="form" noValidate instantValidate={false} onSubmit={(e) => {
                        e.preventDefault();
                        this.setState({ isSaving: true });
                        this.handleSubmit(e).then((data) => {
                            if (data.status != 200) {
                                this.setState({ snackbarOpen: true, snackbarSeverity: "error", snackbarMessage: data.statusText, isSaving: false });
                            } else {
                                this.setState({ snackbarOpen: true, snackbarSeverity: "success", snackbarMessage: "Заявка на регистрацию отправлена", isSaving: false });
                            }
                        })
                        // this.setState({ snackbarOpen: true, snackbarMessage: "Заявка на регистрацию отправлена" });
                    }} >
                    <Snackbar
                        open={this.state.snackbarOpen}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        onClose={this.handleClose}
                    >
                        <Alert onClose={this.handleClose} severity={this.state.snackbarSeverity}>{this.state.snackbarMessage}</Alert>
                    </Snackbar>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} style={{ color: 'white', backgroundColor: '#1976D2' }}>
                            <Typography variant="h6" gutterBottom>{new Date(Date.parse(tournamentInfo.date)).toLocaleDateString(undefined, options).replace(".", "")}</Typography>
                            <Typography variant="h4" gutterBottom>{tournamentInfo.name}</Typography>
                            <Typography variant="h6" gutterBottom>Регистрация до {new Date(Date.parse(tournamentInfo.registrationDeadline)).toLocaleDateString(undefined, options)}</Typography>
                            <Typography variant="body1" gutterBottom><p dangerouslySetInnerHTML={{ __html: tournamentInfo.description.split("\r\n").join("<br />") }} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} >

                            {registrationContent}

                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Box>
        );
    }


    render() {
        let contents = this.state.loading
            ? <p><em>Загрузка...</em></p>
            : this.renderRegistrationForm(this.state.belts, this.state.tournamentInfo);

        return (
            <div>
                <h1 id="tableLabel">Регистрация на турнир</h1>

                {contents}
            </div>
        );
    }

    async populateTournamentData(tournamentId) {
        const beltsResponse = await fetch('api/belts');
        const beltsData = await beltsResponse.json();
        const tournamentResponse = await fetch('api/tournaments/' + tournamentId);
        const tournamentData = await tournamentResponse.json();

        this.setState({
            belts: beltsData,
            loading: false,
            tournamentInfo: tournamentData
        });
    }
}


export const RegisterWithRouter = withRouter(Register);