import React, { Component } from 'react';
import withRouter from "./withRouter";

class Registered extends Component {
    static displayName = Registered.name;

    constructor(props) {
        super(props);
    }
    
    render() {
        let { tournament_id } = this.props.params;
        return (
            <div>
                <h1 id="tableLabel">Ваша заявка на турнир успешно принята</h1>
                <h5>* если вы видите эту страницу, то ваша заявка получена</h5>
                <h5>другого подтверждения на email или телефон отправлено не будет</h5>
                <h5><a href={"/tournaments/" + tournament_id + "/register"}>на странцу турнира</a></h5>
            </div>
        );
    }
}
export const RegisteredWithRouter = withRouter(Registered);