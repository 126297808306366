import React, { Component } from 'react';
import { Box, TextField, Switch, FormControlLabel, Grid, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import withRouter from "../withRouter";
import TournamentNavMenu from './TournamentNavMenu'

export class TournmentSettings extends Component {
    static displayName = TournmentSettings.name;

    constructor(props) {
        super(props);
        let { tournament_id } = this.props.params;
        this.state = {
            tournamentId: tournament_id,
            belts: [],
            tournamentInfo: null,
            loading: true
        };
    }

    componentDidMount() {
        this.populateTournamentData(this.state.tournamentId);
    }

    async populateTournamentData(tournamentId) {
        const beltsResponse = await fetch('api/belts');
        const beltsData = await beltsResponse.json();
        const tournamentResponse = await fetch('api/tournaments/' + tournamentId);
        const tournamentData = await tournamentResponse.json();

        this.setState({
            belts: beltsData,
            loading: false,
            tournamentInfo: tournamentData
        });
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Зарузка...</em></p>
            : this.renderTournamentForm();

        return (
            <div>
                <TournamentNavMenu />
                {contents}
            </div>
        );
    }

    renderTournamentForm = () => {

        const tournamentInfo = this.state.tournamentInfo;
        
        var date = new Date(tournamentInfo.date);
        var deadline = new Date(tournamentInfo.registrationDeadline);
        return (
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <DatePicker
                            id="tournamentDate"
                            name="tournamentDate"
                            label="Дата проведения"
                            value={dayjs(date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate())}
                            readOnly
                            fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControlLabel disabled control={<Switch readOnly checked={tournamentInfo.registrationOpened } />} label="регистрация открыта" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField value={tournamentInfo.name}
                            label="Название"
                            readOnly
                            fullWidth />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <DatePicker
                            label="Регистрация до"
                            value={dayjs(deadline.getFullYear() + "-" + (deadline.getMonth() + 1) + "-" + deadline.getDate())}
                            readOnly
                            fullWidth />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography variant="body1" gutterBottom><p dangerouslySetInnerHTML={{ __html: tournamentInfo.description.split("\r\n").join("<br />") }} /></Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export const TournmentSettingsWithRouter = withRouter(TournmentSettings);