import React from 'react';
import { FormHelperText } from '@mui/material';
import FileUpload from "react-mui-fileuploader"
/* eslint-enable */
import { ValidatorComponent } from 'react-form-validator-core';

export default class FileUploadValidator extends ValidatorComponent {
    renderValidatorComponent() {
        /* eslint-disable no-unused-vars */
        const {
            error,
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            containerProps,
            ...rest
        } = this.props;
        const { isValid } = this.state;
        return (
            <span>
                <FileUpload
                    {...rest}
                />
                <FormHelperText error={!isValid || error}>{(!isValid && this.getErrorMessageProxy()) || helperText}</FormHelperText>
            </span>
        );
    }

    getErrorMessageProxy() {
        let m = this.getErrorMessage();
        return m;
    }
}