import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { pink, blue } from '@mui/material/colors';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PaidSharpIcon from '@mui/icons-material/PaidSharp';
export default class ApplicationCard extends React.Component {
    render() {
        return (
            <Draggable draggableId={this.props.application.id} index={this.props.index}>
                {(provided, snapshot) => (
                  
                    <Card   {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                        sx={{ mt: 0.2 }}>
                        <CardContent style={{ padding:10 }}> 
                            <Typography  variant="h6" component="span"  color={this.props.application.sex == "female" ? pink[500] : blue[700]}>
                                {this.props.application.fio}
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {this.props.application.team}
                            </Typography>
                            {this.props.application.comment == "" ? <span /> :
                                <Tooltip title={this.props.application.comment} arrow>
                                    <Typography noWrap sx={{ fontSize: 14, mb:1 }}>
                                        {this.props.application.comment}
                                    </Typography>
                                </Tooltip>
                            }
                            <Chip label={this.props.application.beltName} />
                            <Chip label={this.props.application.age} />
                            <Chip label={this.props.application.weight + " кг"} />
                            {this.props.subdivisionId == "0" ? <span /> :
                                <IconButton onClick={(e) => this.props.onDelete(this.props.subdivisionId, this.props.application.id, this.props.index)}><DeleteOutlinedIcon /></IconButton>
                            }
                            {
                                this.props.application.paymentConfirmed ?
                                    <Tooltip title="Оплата подтверждена"><PaidSharpIcon color="success" /></Tooltip> :
                                    <Tooltip title="Оплата не подтверждена"><MoneyOffIcon color="error" /></Tooltip>
                            }
                        </CardContent>
                    </Card>

                )}
            </Draggable>
        );
    }
}