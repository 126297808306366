import React from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
/* eslint-enable */
import { ValidatorComponent } from 'react-form-validator-core';

export default class PhoneNumberValidator extends ValidatorComponent {
    renderValidatorComponent() {
        /* eslint-disable no-unused-vars */
        const {
            error,
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            containerProps,
            ...rest
        } = this.props;
        const { isValid } = this.state;
        return (
            <MuiPhoneNumber
                {...rest}
                error={!isValid || error}
                helperText={(!isValid && this.getErrorMessageProxy()) || helperText}
            />
        );
    }

    getErrorMessageProxy() {
        let m = this.getErrorMessage();
        return m;
    }
}