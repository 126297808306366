import React, { Component } from 'react';
import { Navigate } from 'react-router-dom'
import TokenService from '../services/TokenService';


export class Logout extends Component {
    static displayName = Logout.name;

    constructor(props) {
        super(props);
        TokenService.removeUser();
    }


    render() {
        
        return (
            <Navigate to='/login' />
        );
    }
}
