import React from 'react';
import styled from 'styled-components';

const Container = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
  
`;

const MatchContainer = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  text-align:center;
`;

const SkipMatchContainer = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
   background-color: lightgray;
   text-align:center;
`;

const ActiveMatchContainer = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  padding:0px;
  font-weight:600;
  text-align:center;
`;

const ActiveMatchScoreContainer = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  border-right: 0px;
  border-bottom:0px;
  font-weight:400;
  text-align:left
`;

const ActiveMatchScoreTitleContainer = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  border-left: 0px;
  border-bottom:0px;
  font-weight:400;
  text-align:left
`;



export default class Bracket extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }

    }

    render() {
        return (
            <Container>
                <thead>
                    <tr>
                        <th>#</th>
                        {this.props.applications.map((application, index) => {
                            return (
                                <th>{application.fio}</th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {this.props.matches.filter((match) => match.competitor2).map((match, index) => {
                        return (
                            <tr>
                                <MatchContainer>{index + 1}</MatchContainer>
                                {this.props.applications.map((application, index) => {

                                    if (match.competitor1.id == application.id || (match.competitor2 && match.competitor2.id == application.id)) {
                                        return (<ActiveMatchContainer>
                                            <table width="100%"><tbody>
                                                <tr><td colSpan="2">{match.competitor1.id == application.id ? match.competitor1.fio : match.competitor2.fio} {match.competitor1.id == application.id ? "*" : ""}</td></tr>
                                                <tr><ActiveMatchScoreTitleContainer width="30%">рез-тат:</ActiveMatchScoreTitleContainer>
                                                    <ActiveMatchScoreContainer></ActiveMatchScoreContainer></tr>
                                                <tr><ActiveMatchScoreTitleContainer width="30%">баллы:</ActiveMatchScoreTitleContainer>
                                                    <ActiveMatchScoreContainer></ActiveMatchScoreContainer></tr>
                                            </tbody>
                                            </table>
                                        </ActiveMatchContainer>)
                                    }
                                    else {
                                        return (<SkipMatchContainer>-</SkipMatchContainer>)
                                    }
                                })}
                            </tr>)
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <MatchContainer>итого</MatchContainer>
                        {this.props.applications.map((application, index) => {
                            return (
                                <MatchContainer></MatchContainer>
                            )
                        })}
                    </tr>
                </tfoot>
            </Container>
        );
    }
}
